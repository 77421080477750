import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, copyright } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          {/* Brand Section - 1 grid */}
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>

          {/* Company Section - 2 grid */}
          <Grid item xs={12} md={2} sx={{ mb: 3 }}>
            <MKTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={1}>
              Company
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component={Link}
                  to="/about-us"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  About Us
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>

          {/* Help & Support Section - 3 grid */}
          <Grid item xs={12} md={2} sx={{ mb: 3 }}>
            <MKTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={1}>
              Help & Support
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component={Link}
                  to="/contact-us"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Contact Us
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>

          {/* Legal Section - 4 grid */}
          <Grid item xs={12} md={2} sx={{ mb: 3 }}>
            <MKTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={1}>
              Legal
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Terms & Conditions
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Privacy Policy
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>

          {/* Contact Us Section - 4 grid */}
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <MKTypography variant="button" fontWeight="bold" textTransform="capitalize" mb={1}>
              Contact Us
            </MKTypography>
            <MKBox>
              <MKTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                component="div"
                display="flex"
                flexDirection="column"
                sx={{ marginBottom: 1, padding: 1 }}
              >
                <strong>Email:</strong>
                <span>support@videsistays.com</span>
                <span>Contact@videsistays.com</span>
              </MKTypography>

              <MKTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                component="div"
                display="flex"
                flexDirection="column"
                sx={{ padding: 1 }}
              >
                <strong>Phone & WhatsApp:</strong>
                <span>7995877701/02</span>
              </MKTypography>
            </MKBox>
          </Grid>

          {/* Copyright Section */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.shape({
    brand: PropTypes.object.isRequired,
    socials: PropTypes.array.isRequired,
    copyright: PropTypes.node.isRequired,
    contactDetails: PropTypes.shape({
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DefaultFooter;
